<template>
    <div class="tw-border tw-p-3 tw-rounded tw-shadow">
        <div class="row">
            <div class="col-md-6 tw-space-y-3">
                <div class="row">
                    <label class="col-md-3 col-form-label required">{{
                        $t('serviceTicket.serviceDate')
                    }}</label>
                    <div class="col-md-6">
                        <DatePicker
                            :value="model.service_date"
                            type="datetime"
                            style="width: 100%"
                            placeholder="DD-MM-YYYY HH:mm:ss"
                            format="dd-MM-yyyy HH:mm:ss"
                            @on-change="onChangeServiceDate"
                        ></DatePicker>
                        <div
                            class="text-danger"
                            v-if="errors.has('service_date')"
                        >
                            {{ errors.first('service_date') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3 col-form-label">{{
                        $t('serviceTicket.ticketOwner')
                    }}</label>
                    <div class="col-md-6">
                        <Select
                            v-model.number="model.ticket_owner"
                            :filter-by-label="true"
                            :placeholder="$t('select')"
                            :clearable="true"
                            filterable
                            @on-change="onChangeTicketOwner"
                            @on-query-change="handleSearchEmployee"
                        >
                            <Option
                                v-for="employee in employeeList"
                                :value="employee.employee_id"
                                :key="employee.employee_id"
                                :label="employee.employee_name_en"
                            >
                                {{ employee.employee_name_en }}
                            </Option>
                        </Select>
                        <div
                            class="text-danger"
                            v-if="errors.has('ticket_owner')"
                        >
                            {{ errors.first('ticket_owner') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3 col-form-label required">{{
                        $t('serviceTicket.ticketStatus')
                    }}</label>
                    <div class="col-md-6">
                        <Select
                            v-model.number="model.ticket_status"
                            @on-change="onChangeTicketStatus"
                            :placeholder="$t('select')"
                        >
                            <Option
                                v-for="ticketStatus in getTicketStatusList(
                                    model.ticket_status
                                )"
                                :value="ticketStatus.ticket_status_id"
                                :key="ticketStatus.ticket_status_id"
                                :label="ticketStatus.ticket_status"
                            >
                                {{ ticketStatus.ticket_status }}
                            </Option>
                        </Select>
                        <div
                            class="text-danger"
                            v-if="errors.has('ticket_status')"
                        >
                            {{ errors.first('ticket_status') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 tw-space-y-3">
                <div class="row">
                    <label class="col-md-3 col-form-label required">{{
                        $t('serviceTicket.customer')
                    }}</label>
                    <div class="col-md-6">
                        <div class="tw-flex">
                            <Select
                                v-model="model.customer_id"
                                :filter-by-label="true"
                                :placeholder="$t('select')"
                                :clearable="true"
                                filterable
                                @on-query-change="handleSearchCustomer"
                            >
                                <Option
                                    v-for="customer in customerList"
                                    :value="customer.customer_id"
                                    :key="customer.customer_id"
                                    :label="customer.customer_name"
                                >
                                    {{ customer.customer_name }}
                                </Option>
                            </Select>
                            <a
                                href="#"
                                @click.prevent="onCreateCustomer"
                                v-tooltip="$t('addNew')"
                                style="background: #3990e0"
                                class="tw-h-8 tw-w-8 tw-text-white tw-flex tw-items-center tw-rounded-tr tw-rounded-br hover:tw-text-white"
                                ><i class="fas fa-plus-square tw-mx-auto"></i
                            ></a>
                        </div>
                        <div
                            class="text-danger"
                            v-if="errors.has('customer_id')"
                        >
                            {{ errors.first('customer_id') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3 col-form-label">{{
                        $t('serviceTicket.remarks')
                    }}</label>
                    <div class="col-md-6">
                        <textarea
                            v-model="model.remarks"
                            rows="3"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has('remarks')
                            }"
                        />
                        <div class="validate" v-if="errors.has('remarks')">
                            {{ errors.first('remarks') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Drawer
            :title="$t('serviceTicket.createNewCustomer')"
            :closable="false"
            :value="add_new_customer"
            @on-close="() => (add_new_customer = false)"
            width="400px"
        >
            <AddNewCustomer
                ref="add_new_customer"
                @cancel="onCancel"
                @data="setCustomer"
            />
        </Drawer>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapGetters, mapState } from 'vuex'
import AddNewCustomer from './add-new-customer.vue'
import { debounce } from 'lodash'

export default {
    name: 'quotationInfo',
    props: ['value', 'validate'],
    components: {
        AddNewCustomer
    },
    data () {
        return {
            errors: new Errors(),
            add_new_customer: false,
            waiting_new: false,
            waiting: false,
            loading: false,
            model: {},
            is_disabled: false,
            searching: false
        }
    },
    created () {
        this.model = this.value
    },
    computed: {
        ...mapState('customerService/serviceTicket', ['formViewDatas']),
        ...mapGetters('customerService/serviceTicket', ['getTicketStatusList']),
        customerList () {
            return this.formViewDatas.customers
        },
        employeeList () {
            return this.formViewDatas.employee
        },
        ticketStatusList () {
            return this.formViewDatas.serviceTicketStatus
        }
    },
    methods: {
        ...mapActions('customerService/serviceTicket', ['getFormViewModel']),
        handleSearchEmployee: debounce(function (value) {
            this.loading = true
            this.getFormViewModel({
                index: 'employee',
                params: {
                    fnName: 'employee',
                    search: value
                }
            }).finally(() => (this.loading = false))
        }, 800),
        handleSearchCustomer: debounce(function (value) {
            this.loading = true
            this.getFormViewModel({
                index: 'customers',
                params: {
                    fnName: 'customers',
                    search: value
                }
            }).finally(() => (this.loading = false))
        }, 800),
        onChangeServiceDate (date) {
            this.model.service_date = date
        },
        onChangeExpectedCompletionDate (date) {
            this.model.expected_completion_date = date
        },
        onChangeTicketOwner (value) {
            if (value) {
                this.model.ticket_status = 2
            } else {
                this.model.ticket_status = 1
            }
        },
        onChangeTicketStatus (value) {
            if (value == 1) {
                this.model.ticket_owner = null
            }
        },
        setCustomer (data) {
            this.$store.commit(
                'customerService/serviceTicket/PUSH_NEW_CUSTOMER',
                data
            )
            this.model.customer_id = data.customer_id
        },
        onCreateCustomer () {
            this.$refs.add_new_customer.fetchResources()
            this.add_new_customer = true
        },
        onCancel () {
            this.add_new_customer = false
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
