<template>
    <div>
        <ts-loading-banner :loading="loading">
            <div class="tw-space-y-3">
                <div class="row">
                    <div class="col-md-12">
                        <label class="tw-text-xs required">{{
                            $t('customerProfile.customerName')
                        }}</label>
                        <input
                            v-model="model.customer_name"
                            type="text"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has('customer_name')
                            }"
                        />
                        <div
                            class="invalid-feedback tw-text-xs"
                            v-if="errors.has('customer_name')"
                        >
                            {{ errors.first('customer_name') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="tw-text-xs required">{{
                            $t('customerProfile.gender')
                        }}</label>
                        <div class="tw-w-full">
                            <RadioGroup v-model="model.gender">
                                <Radio label="Male"> Male </Radio>
                                <Radio label="android"> Female </Radio>
                                <Radio label="windows"> Other </Radio>
                            </RadioGroup>
                        </div>
                        <div
                            class="validate tw-text-xs"
                            v-if="errors.has('gender')"
                        >
                            {{ errors.first('gender') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <label class="tw-text-xs required">{{
                            $t('customerProfile.phoneNumber')
                        }}</label>
                        <input
                            v-model.trim="model.contact_number"
                            type="text"
                            class="form-control"
                            :class="{
                                'is-invalid': errors.has('contact_number')
                            }"
                        />
                        <div
                            class="invalid-feedback tw-text-xs"
                            v-if="errors.has('contact_number')"
                        >
                            {{ errors.first('contact_number') }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="tw-text-xs">{{
                            $t('customerProfile.phoneNumber2')
                        }}</label>
                        <input
                            v-model="model.contact_number2"
                            type="text"
                            class="form-control"
                        />
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <label class="form-label tw-text-xs">{{
                            $t('customerProfile.telegram')
                        }}</label>
                        <input
                            v-model="model.telegram"
                            type="text"
                            class="form-control"
                        />
                    </div>
                    <div class="col-md-6">
                        <label class="form-label tw-text-xs">{{
                            $t('customerProfile.facebook')
                        }}</label>
                        <input
                            v-model="model.facebook_account"
                            type="text"
                            class="form-control"
                        />
                    </div>
                </div>
                <div class="row tw-mt-2">
                    <div class="col-md-12">
                        <label class="tw-text-xs">{{
                            $t('customerProfile.idCard')
                        }}</label>
                        <input
                            v-model.trim="model.ss_id_card"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.has('ss_id_card') }"
                        />
                        <div
                            class="invalid-feedback tw-text-xs"
                            v-if="errors.has('ss_id_card')"
                        >
                            {{ errors.first('ss_id_card') }}
                        </div>
                    </div>
                </div>
                <div class="row tw-mt-3">
                    <div class="col-md-12">
                        <h4 class="tw-uppercase tw-text-gray-500">
                            {{ $t('customerProfile.address') }}
                        </h4>
                        <div
                            class="tw-border-blue-300 tw-border tw-rounded tw-p-3"
                        >
                            <div class="row tw-space-y-2">
                                <div class="col-md-12">
                                    <label class="tw-text-xs">{{
                                        $t('customerProfile.province')
                                    }}</label>
                                    <Select
                                        v-model="model.province_code"
                                        @on-change="onProvinceChange"
                                        class="tw-w-full"
                                        filterable
                                    >
                                        <Option
                                            v-for="province in provinces"
                                            :value="province.province_code"
                                            :key="province.province_code"
                                            :label="province.name_en"
                                        >
                                            {{ province.name_en }}
                                        </Option>
                                    </Select>
                                    <div
                                        class="validate tw-text-xs"
                                        v-if="errors.has('province_code')"
                                    >
                                        {{ errors.first('province_code') }}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="tw-text-xs">{{
                                        $t('customerProfile.district')
                                    }}</label>
                                    <Select
                                        v-model="model.district_code"
                                        filterable
                                        @on-change="onDistrictChange"
                                        class="tw-w-full"
                                    >
                                        <Option
                                            v-for="district in districts"
                                            :value="district.district_code"
                                            :key="district.district_code"
                                            :label="district.name_en"
                                        >
                                            {{ district.name_en }}
                                        </Option>
                                    </Select>
                                    <div
                                        class="validate tw-text-xs"
                                        v-if="errors.has('district_code')"
                                    >
                                        {{ errors.first('district_code') }}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="tw-text-xs">{{
                                        $t('customerProfile.commune')
                                    }}</label>
                                    <Select
                                        v-model="model.commune_code"
                                        @on-change="onCommuneChange"
                                        filterable
                                        class="tw-w-full"
                                    >
                                        <Option
                                            v-for="commune in communes"
                                            :value="commune.commune_code"
                                            :key="commune.commune_code"
                                            :label="commune.name_en"
                                        >
                                            {{ commune.name_en }}
                                        </Option>
                                    </Select>
                                    <div
                                        class="validate tw-text-xs"
                                        v-if="errors.has('commune_code')"
                                    >
                                        {{ errors.first('commune_code') }}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="tw-text-xs">{{
                                        $t('customerProfile.village')
                                    }}</label>
                                    <Select
                                        v-model="model.village_code"
                                        filterable
                                        class="tw-w-full"
                                    >
                                        <Option
                                            v-for="village in villages"
                                            :value="village.village_code"
                                            :key="village.village_code"
                                            :label="village.name_en"
                                        >
                                            {{ village.name_en }}
                                        </Option>
                                    </Select>
                                    <div
                                        class="validate tw-text-xs"
                                        v-if="errors.has('village_code')"
                                    >
                                        {{ errors.first('village_code') }}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="ts-text-xs">{{
                                        $t('customerProfile.zipCode')
                                    }}</label>
                                    <input
                                        v-model.trim="model.zip_code"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has('zip_code')
                                        }"
                                    />
                                    <div
                                        class="invalid-feedback ts-text-xs"
                                        v-if="errors.has('zip_code')"
                                    >
                                        {{ errors.first('zip_code') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ts-loading-banner>
        <div
            :style="{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px'
            }"
        >
            <ts-button class="tw-mr-2" @click.prevent="() => $emit('cancel')">
                {{ $t('cancel') }}
            </ts-button>
            <ts-button
                color="primary"
                @click.prevent="onSave"
                :waiting="waiting"
                >{{ $t('save') }}</ts-button
            >
        </div>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'companyForm',
    data () {
        return {
            loading: false,
            errors: new Errors(),
            waiting: false,
            provinces: [],
            districts: [],
            communes: [],
            villages: [],
            model: {
                customer_id: 0,
                cus_type_id: 5, // for sale lok dom
                customer_name: null,
                gender: null,
                contact_number: null,
                contact_email: null,
                facebook_account: null,
                telegram: null,
                city_province: null,
                year_of_born: null,
                account_number: null,
                is_general_customer: false,
                is_membership: false,
                is_allow_update_gps_location: false,
                membership_discount_rate: 0,
                company_name: null,
                zip_code: null,
                credit_day: 0,
                credit_amount: 0,
                province_code: null,
                disctrict_code: null,
                commune_code: null,
                village_code: null,
                sale_id: null,
                ss_id_card: null,
                contact_number2: null,
                approve_status: 2 // set default to approve
            }
        }
    },
    // created () {
    //     this.fetchResources()
    // },
    computed: {
        ...mapState('customer/profile', ['edit_data'])
    },
    methods: {
        ...mapActions('customer/profile', ['getFormViewModel']),
        onSave () {
            this.waiting = true
            this.$store
                .dispatch('customer/profile/store', this.model)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$emit('data', response.data)
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async fetchResources () {
            this.loading = true
            let response = await this.getFormViewModel({
                fnName: 'provinces'
            })

            this.provinces = response.data['provinces']
            this.loading = false
        },
        async onProvinceChange (value) {
            this.model.disctrict_code = null
            this.model.commune_code = null
            this.model.village_code = null
            this.loading = true

            await this.getFormViewModel({
                fnName: 'districts',
                province_code: value
            })
                .then(response => {
                    this.districts = response.data['districts']
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async onDistrictChange (value) {
            this.model.commune_code = null
            this.model.village_code = null
            this.loading = true
            await this.getFormViewModel({
                fnName: 'communes',
                district_code: value
            })
                .then(response => {
                    this.communes = response.data['communes']
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async onCommuneChange (value) {
            this.model.village_code = null
            this.loading = true
            await this.getFormViewModel({
                fnName: 'villages',
                commune_code: value
            })
                .then(response => {
                    this.villages = response.data['villages']
                })
                .finally(() => {
                    this.loading = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'SERVICE TICKET',
                desc: not.text
            })
        }
    }
}
</script>
